/* eslint-disable */
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import reduce from 'lodash/reduce';

import {
  NAMESPACE as MyBookingsNameSpace,
  store as MyBookingsStore,
} from '@/stores/Booking/stores/MyBookings/MyBookingsStore';
import {
  NAMESPACE as KeyCardNameSpace,
  store as KeyCardStore,
} from '@/domains/Document/Keycard/KeyCardModule';
import {
  NAMESPACE as DrivingLicenseRoadblockNameSpace,
  store as DrivingLicenseRoadblockStore,
} from '@/components/DrivingLicenseRoadblock/DrivingLicenseRoadblockModule';
import ProfileCostAllocationsModule, { NAMESPACE as ProfileCostAllocationsNamespace } from '@Profile/CostAllocations/store/ProfileCostAllocationsModule';

import Profile, { nameSpace as ProfileNameSpace } from '@Profile/store';

import UserData, { nameSpace as UserDataNameSpace } from './UserData/UserDataStore';
import BookingType, { nameSpace as BookingTypeNameSpace } from './BookingType/BookingTypeStore';
import Tariff, { nameSpace as TariffNameSpace } from './Tariff/TariffStore';
import { CityStore, NAMESPACE as CityStoreNameSpace } from '@/stores/City/CityStore';
import { CountryStore, NAMESPACE as CountryStoreNameSpace } from '@/stores/Country/CountryStore';
import DateTime, { nameSpace as DateTimeNameSpace } from './DateTime/DateTimeStore';
import Availability, { nameSpace as AvailabilityNameSpace } from '@Vehicle/store/Availability/AvailabilityStore';
import Booking, { nameSpace as BookingNameSpace } from './Booking/BookingStore';

import ActiveTab, { nameSpace as ActiveTabNameSpace } from './ActiveTab/ActiveTabStore';
import UserInsurance, { nameSpace as UserInsuranceNameSpace } from './UserInsurance/UserInsuranceStore';
import CSOperator, { nameSpace as CSOperatorNameSpace } from './CSOperator/CSOperatorStore';
import CompanyData, { nameSpace as CompanyDataNameSpace } from './Company/CompanyStore';
import ServiceProvider, { nameSpace as ServiceProviderNameSpace } from './ServiceProvider/ServiceProviderStore';
import CurrentProfileTariff, { nameSpace as CurrentProfileTariffNameSpace } from './Tariff/CurrentProfile/Tariff';
import CompanyTariff, { nameSpace as CompanyTariffNameSpace } from './Tariff/Company/Tariff';
import AdyenStoreHub, { nameSpace as AdyenStoreHubNameSpace } from '@Shared/Payments/PaymentMethodsHub/paymentMethodsProviders/Adyen/stores/adyenStore';
import PaymentMethodsHubStore, { nameSpace as PaymentMethodsHubNameSpace } from '@Shared/Payments/PaymentMethodsHub/stores/PaymentMethodsHub';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';
import OperatorLocationsModule, { NAMESPACE as OperatorLocationsNamespace } from '@/stores/OperatorLocations/OperatorLocationsModule';
import { NAMESPACE as CompanySettingsNamespace, CompanySettingsModule } from '@/stores/Company/UserCompanyAccount/CompanySettingsModule';
import { NAMESPACE as UserCompanySettingsNamespace, UserCompanySettingsModule } from '@/stores/Company/UserCompany/UserCompanySettingsModule';
import { NAMESPACE as VehicleUserNamespace, store as VehicleUserModule } from '@/stores/VehicleUser/VehicleUserModule';
import { NAMESPACE as FavouriteLocationsNamespace, FavouriteLocationsModule } from '@/stores/FavouriteLocations/FavouriteLocationsModule';
import { NAMESPACE as ThemeNamespace, store as ThemeModule } from '@/stores/Theme/ThemeModule';

import { NAMESPACE as VehicleAvailabilityNamespace, default as VehicleAvailabilityModule } from '@Vehicle/store/VehicleAvailability/VehicleAvailabilityModule';
import Loader, { nameSpace as LoaderNameSpace } from '@Shared/store/Loader/LoaderStore';

// Refactor Reducer
import Refactor, { nameSpace as RefactorNameSpace } from '../refactor/core/store/index';

// RunasyncFlow store
import UserValidationsModule from '@/stores/UserValidations/UserValidationsModule';

import { Tariffs } from '@/stores/Tariffs/TariffsModule';
import { Promotions } from '@/stores/User/Promotions/PromotionsModule';
import { Carpooling, NAMESPACE as CarpoolingNameSpace } from '@/domains/Carpooling/store/CarpoolingModule';
import { Insurances } from '@/stores/Insurances/InsurancesModule';
import * as LegalDocumentsModule from '@/stores/User/LegalDocuments/LegalDocumentsModule';
import { Logbook, NAMESPACE as LogbookNamespace } from '@/stores/Logbook/LogbookModule';
import {
  NAMESPACE as CostAllocationNamespace,
  store as CostAllocationModuleStore
} from '@/stores/CostAllocation/CostAllocationModule';

import {
  NAMESPACE as BookingModuleNameSpace,
  store as BookingModuleStore
} from '@/stores/Booking/BookingModule';
import { CostAllocations } from '@/vue/stores/BookingSummary/CostAllocations';
import {
  NAMESPACE as UserModuleNameSpace,
  store as UserModuleStore
} from '@/stores/User/UserModule';

import { NAMESPACE as InvoicesNameSpace, default as Invoices } from '@/vue/stores/Invoices/InvoicesModule';

import { NAMESPACE as AppNotificationNameSpace, default as AppNotificationModule } from '@Shared/store/AppNotifications/AppNotificationsModule';
import { PhishingBadgeStore, NAMESPACE as PhishingBadgeNameSpace } from '@Shared/PhishingBadge/store/PhishingBadgeStore';

const VuexStore = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['Refactor'], // Persist just Refactor storage
    reducer: (state) => {
      const newState = cloneDeep(state);
      delete newState.Refactor.SignUp.Company.done.step1;
      delete newState.Refactor.SignUp.Company.done.step2;
      delete newState.Refactor.SignUp.Company.done.step3;
      delete newState.Refactor.SignUp.Company.data.adminIDBase64;
      delete newState.Refactor.SignUp.Company.data.incorporationProofBase64;
      delete newState.Refactor.SignUp.Company.data.italyTermsAndConditions;
      delete newState.Refactor.SignUp.Company.data.marketingTerms;
      return { Refactor: newState.Refactor };
    },
  })],
  modules: {
    SignIn: {
      namespaced: true,
      state: {
        email: '',
        password: '',
      },
      mutations: {
        updateEmail(state, value) {
          state.email = value;
        },
        updatePassword(state, value) {
          state.password = value;
        },
      },
    },
    [UserDataNameSpace]: UserData,

    [LoaderNameSpace]: Loader,

    [BookingTypeNameSpace]: BookingType,

    [ProfileNameSpace]: Profile,

    [TariffNameSpace]: Tariff,

    [CityStoreNameSpace]: CityStore,

    [CountryStoreNameSpace]: CountryStore,

    [DateTimeNameSpace]: DateTime,

    [AvailabilityNameSpace]: Availability,

    [BookingNameSpace]: Booking,

    [ActiveTabNameSpace]: ActiveTab,

    [UserInsuranceNameSpace]: UserInsurance,

    [CSOperatorNameSpace]: CSOperator,

    [CompanyDataNameSpace]: CompanyData,

    [ServiceProviderNameSpace]: ServiceProvider,

    [CompanyTariffNameSpace]: CompanyTariff,

    [CurrentProfileTariffNameSpace]: CurrentProfileTariff,

    [RefactorNameSpace]: Refactor,

    [MyBookingsNameSpace]: MyBookingsStore,

    [KeyCardNameSpace]: KeyCardStore,

    [DrivingLicenseRoadblockNameSpace]: DrivingLicenseRoadblockStore,

    [ProfileCostAllocationsNamespace]: ProfileCostAllocationsModule.store,

    [VehicleUserNamespace]: VehicleUserModule,

    [ThemeNamespace]: ThemeModule,

    [VehicleAvailabilityNamespace]: VehicleAvailabilityModule,

    [AdyenStoreHubNameSpace]: AdyenStoreHub,
    [PaymentMethodsHubNameSpace]: PaymentMethodsHubStore,

    [OperatorLocationsNamespace]: OperatorLocationsModule,

    [BookingCostAllocationModule.NAMESPACE]: BookingCostAllocationModule.store,

    CostAllocations,

    [UserValidationsModule.NAMESPACE]: UserValidationsModule.store,

    Tariffs,
    Insurances,
    Promotions,

    [CarpoolingNameSpace]: Carpooling,

    [LogbookNamespace]: Logbook,

    [PhishingBadgeNameSpace]: PhishingBadgeStore,

    [BookingModuleNameSpace]: BookingModuleStore,

    [CostAllocationNamespace]: CostAllocationModuleStore,

    [UserModuleNameSpace]: UserModuleStore,

    [LegalDocumentsModule.NAMESPACE]: LegalDocumentsModule.store,

    [CompanySettingsNamespace]: CompanySettingsModule,

    [FavouriteLocationsNamespace]: FavouriteLocationsModule,

    [UserCompanySettingsNamespace]: UserCompanySettingsModule,

    [InvoicesNameSpace]: Invoices,

    [AppNotificationNameSpace]: AppNotificationModule,
  },
});

export const customMapActions = (namespace, actions) => {
  const isActionsAnArray = isArray(actions);
  const isActionsAnObject = isPlainObject(actions);

  if (isActionsAnArray) {
    return reduce(actions, (accumulator, value) => ({
      ...accumulator,
      [value]: (...params) => VuexStore.dispatch(`${namespace}/${value}`, ...params),
    }), {});
  }

  if (isActionsAnObject) {
    return reduce(actions, (accumulator, value, key) => ({
      ...accumulator,
      [key]: (...params) => VuexStore.dispatch(`${namespace}/${value}`, ...params),
    }), {});
  }

  return {};
};

export default VuexStore;
