import isNull from 'lodash/isNull';
import find from 'lodash/find';
import { external } from '@emobg/web-api-client';

import { CSOperator } from '@Shared/legacy/CSOperator';

import { MUTATIONS } from './_mutations';

const CSOperatorNameSpace = 'CSOperator';
const CS_OPERATOR_PROPERTIES = [
  'uuid',
  'name',
  'customer_service_phone',
  'customer_service_email',
  'customer_service_opening_hours',
  'country_code',
  'help_link',
  'configuration.badge_required',
  'configuration.carsharing_default_duration',
  'configuration.interval_time_unit',
  'configuration.chatbot_app_id',
  'configuration.cs_minimum_booking_duration',
  'configuration.cs_maximum_booking_duration',
  'configuration.cs_maximum_future_booking_time',
  'configuration.cs_booking_gap',
  'configuration.ld_minimum_booking_duration',
  'configuration.ld_maximum_booking_duration',
  'configuration.ld_booking_gap',
  'configuration.in_minimum_booking_duration',
  'configuration.in_maximum_booking_duration',
  'configuration.in_booking_gap',
  'configuration.default_cs_minimum_booking_duration',
  'configuration.default_ld_minimum_booking_duration',
  'configuration.default_in_minimum_booking_duration',
  'configuration.default_cs_maximum_booking_duration',
  'configuration.default_ld_maximun_booking_duration',
  'configuration.default_in_maximun_booking_duration',
  'configuration.uses_pre_booking',
  'configuration.has_one_way',
  'configuration.badge_pickup_available',
  'configuration.badge_pickup_price',
  'configuration.first_step_video_link',
  'configuration.currency_code',
  'configuration.dl_review_days',
  'configuration.restrictive_badge_provider_id',
  'configuration.availability_filters_enabled',
  'configuration.allow_recurring_bookings',
  'configuration.max_recurring_bookings',
  'configuration.long_distance_allowed',
  'configuration.allow_additional_driver',
  'configuration.allow_passengers',
  'configuration.allow_automatic_dl_review_renewal',
  'configuration.close_into_future',
  'configuration.mileage_symbol',
  'configuration.allow_carpooling',
  'configuration.mileage_unit',
  'configuration.show_dlva_code',
  'configuration.use_restrictive_badge',
  'configuration.has_logbook',
  'configuration.default_ld_maximum_booking_duration',
  'configuration.long_distance_default_duration',
  'configuration.intervention_default_duration',
  'configuration.default_in_maximum_booking_duration',
  'configuration.ld_maximum_future_booking_time',
  'configuration.currency_symbol',
  'configuration.min_driver_age',
  'configuration.min_license_years',
  'configuration.stack_availability_enabled',
  'configuration.in_maximum_future_booking_time',
  'configuration.minutes_to_book_before_current_time',
  'configuration.promote_vehicles_in_the_search_result',
  'configuration.driving_license_required',
  'configuration.add_automatic_badge',
];

export const ACTIONS = {
  setCurrentCSOperator: 'setCurrentCSOperator',
  addCSOperatorInCollection: 'addCSOperatorInCollection',
  setCSOperatorCollection: 'setCSOperatorCollection',
  fetchCSOperator: 'fetchCSOperator',
  setVisitedCsOperator: 'setVisitedCsOperator',
  resetCSOperatorStore: 'resetCSOperatorStore',
};

export default {
  [ACTIONS.setCurrentCSOperator](context, data) {
    let obj = data;

    // If it's different, data is not a CSOperator
    // object, it's a plain object
    if (obj.constructor.name !== CSOperator) {
      obj = new CSOperator(data);
    }

    context.commit(MUTATIONS.SET_CURRENT_CS_OPERATOR, obj);
  },

  [ACTIONS.addCSOperatorInCollection](context, data) {
    let obj = data;

    // If it's different, data is not a CSOperator
    // object, it's a plain object
    if (obj.constructor.name !== CSOperator) {
      obj = new CSOperator(data);
    }

    context.commit(MUTATIONS.ADD_CS_OPERATOR_IN_COLLECTION, obj);
  },

  [ACTIONS.setCSOperatorCollection](context, operatorArray) {
    const collection = operatorArray.map(operator => ((operator.constructor.name !== CSOperator)
      ? new CSOperator(operator)
      : operator
    ));

    context.commit(MUTATIONS.ADD_CS_OPERATOR_IN_COLLECTION, collection);
  },

  async [ACTIONS.fetchCSOperator](context, uuid) {
    let operator = context.getters.findCSOperatorByUUID(uuid);

    // If CSOperator exists in Store, return it
    if (operator) {
      return operator;
    }

    // Get SessionStorage for this module
    let CSOperatorCollectionSessionStore
      = window.sessionStorage.getItem(`${CSOperatorNameSpace}/csoperators`);

    // SessionStorage has not the CS Operator key
    // is because it's empty
    if (!CSOperatorCollectionSessionStore) {
      const response = await external.userCompanyCSOperator.getOperatorDetails(uuid, undefined, CS_OPERATOR_PROPERTIES);

      CSOperatorCollectionSessionStore = JSON.stringify([response]);

      window.sessionStorage.setItem(`${CSOperatorNameSpace}/csoperators`, CSOperatorCollectionSessionStore);
    }

    // SessionStorage exists
    // Get his data and search the CS Operator with
    // UUID passed by parameter
    const sessionStoreParsed = JSON.parse(CSOperatorCollectionSessionStore);
    // sessionStoreParsed contains an array
    operator = find(sessionStoreParsed, ['uuid', uuid]);

    // If the CS Operator doesn't exist in SessionStorage
    // we have to created it and set it
    if (!operator) {
      const response = await external.userCompanyCSOperator.getOperatorDetails(uuid, undefined, CS_OPERATOR_PROPERTIES);

      const collection = [...sessionStoreParsed, response];

      window.sessionStorage.setItem(`${CSOperatorNameSpace}/csoperators`, JSON.stringify(collection));

      return context.dispatch('fetchCSOperator', uuid);
    }

    // If the CS Operator exists
    // it is added in the Store collection
    // and it's returned
    await context.dispatch('addCSOperatorInCollection', operator);

    return context.dispatch('fetchCSOperator', uuid);
  },

  [ACTIONS.setVisitedCsOperator](context, data) {
    let visitedCsOperator = data;
    // If it's different, data is not a CSOperator
    // object, it's a plain object
    if (!isNull(visitedCsOperator) && visitedCsOperator.constructor.name !== CSOperator) {
      visitedCsOperator = new CSOperator(data);
    }

    context.commit(MUTATIONS.SET_CS_OPERATOR_VISITED, visitedCsOperator);
  },

  [ACTIONS.resetCSOperatorStore](context) {
    context.commit(MUTATIONS.RESET_STATE);
  },
};
